import React, { useState } from "react";
import axios from "axios";

import {
  Alert,
  Box,
  TextField,
  Typography,
  IconButton,
  Snackbar,
} from "@mui/material";

import { ClearOutlined, ArrowUpward } from "@mui/icons-material";

import moment from "moment";
import { API_BASE } from "@/App";
import { fromNow } from "@/utils";

export default function CommentsContainer({ target }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [comments, setComments] = useState(
    target["comments"].sort((a, b) => b.created - a.created)
  );
  const [comment, setComment] = useState("");

  const submitComment = async () => {
    try {
      setLoading(true);
      const endpoint = API_BASE + "comment"; // Update the endpoint to match the new route

      const response = await axios.post(endpoint, {
        target_id: target["id"], // Assuming you have the target's ID
        actor_key: localStorage.getItem("actorKey"),
        text: comment, // The actual comment text
      });

      let newComment = {
        id: response.data["id"],
        author: "Me",
        actor_key: localStorage.getItem("actorKey"),
        text: comment, // Use the correct key for the comment text
        created: moment().unix(),
      };

      setComment("");
      setComments([newComment, ...comments]);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteComment = async (comment_id) => {
    try {
      // Optimistically update the UI before making the API call
      setComments(comments.filter((comment) => comment["id"] !== comment_id));
      setLoading(true);

      const endpoint = API_BASE + "comment";

      const response = await axios.delete(endpoint, {
        data: {
          id: comment_id, // Ensure the key matches what the server expects
          actor_key: localStorage.getItem("actorKey"),
        },
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ my: 2 }}>
      <Box
        id="add-comment-form"
        style={{ display: "flex", alignItems: "flex-start" }}
      >
        <TextField
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          onKeyDown={(e) => {
            if (e.ctrlKey && e.key === "Enter") {
              submitComment();
            }
          }}
          sx={{ mr: 2, "& .MuiOutlinedInput-root": { borderRadius: "30px" } }} // Adjust the value as needed
          style={{ width: "350px" }}
          label="Add a comment..."
          variant="outlined"
          multiline
          autoComplete="off"
          size="small"
          autoFocus={false}
          disabled={loading}
        />

        <IconButton
          onClick={submitComment}
          disabled={loading}
          style={{ background: "#1976d2" }}
        >
          <ArrowUpward />
        </IconButton>
      </Box>
      <Box id="the-comments-themselves">
        {comments.map((comment, index) => (
          <Box key={index}>
            <CommentContainer comment={comment} deleteComment={deleteComment} />
          </Box>
        ))}
      </Box>

      {error && (
        <Snackbar
          open={true}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Alert
            onClose={() => setError(null)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}

function CommentContainer({ comment, deleteComment }) {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
      sx={{ my: 4 }}
    >
      <Box>
        <Typography
          variant="caption"
          color="lightgray"
          style={{ marginRight: "15px" }}
        >
          {comment["author"]}
        </Typography>
        <Typography variant="body">{comment.text}</Typography>
      </Box>

      <Box
        id="comment-actions"
        style={{ display: "flex", minWidth: "80px" }}
        sx={{ mx: 2 }}
      >
        <Typography id="time-ago" variant="caption" color="lightgray">
          {" "}
          {fromNow(comment["created"])}
        </Typography>

        <Box id="edit">
          <ClearOutlined
            style={{
              color: "darkgray",
              cursor: "pointer",
              marginLeft: "10px",
              marginTop: "-2px",
              fontSize: "1em",
            }}
            onClick={() => deleteComment(comment["id"])}
          />
        </Box>
      </Box>
    </Box>
  );
}
