import React from 'react';


import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import BarChartIcon from '@mui/icons-material/BarChart';

import { API_BASE } from '../../App';


export default function AnalyticsMenu() {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const analyticsUrl = (view) => {
        const endpoint = API_BASE + 'analytics'
        return `${endpoint}?view=${view}&actor_key=${localStorage.getItem("actorKey")}&token=${localStorage.getItem("accessToken")}`
    }

    const views = [
        "daily_validations", 
        "weekly_validations",
        "weekly_rejects",
        "last_week_leaderboard",
        "monthly_leaderboard",
        "annual_leaderboard",
    ]

    return (
        <Box style={{ margin: '0 25px' }}>
            <IconButton
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                color='info'
            >
                <BarChartIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {views.map((views, index) => (
                    <MenuItem key={index} color='info'>
                        <Link href={analyticsUrl(views)} target="_blank" style={{color: 'white', textDecoration: 'none'}}>
                            {views}
                        </Link>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );

}