import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

import TargetCard from "@/components/TargetCard";

export default function TargetCardContainer({
  searchData,
  targets,
  setTargets,
  fetchTargetCount,
  fetchTargets,
  loading,
}) {
  const [perPage, setPerPage] = useState(
    parseInt(localStorage.getItem("perPage")) || 10
  );
  const [currentPage, setCurrentPage] = useState(0); // Starts from page 0

  const totalRows = targets.length;
  const totalPages = Math.ceil(totalRows / perPage);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value));
    setCurrentPage(0); // Reset to the first page when changing rows per page
    localStorage.setItem("perPage", e.target.value);
  };

  const paginatedTargets = targets.slice(
    currentPage * perPage,
    currentPage * perPage + perPage
  );

  return (
    <Box sx={{ width: "calc(100vw - 240px)" }}>
      <Box sx={{ my: 3, pb: 20 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <SortContainer
            targets={targets}
            setTargets={setTargets}
            perPage={perPage}
            setPerPage={handlePerPageChange}
          />
          {/* Pagination Controls */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // gap: 2,
            }}
          >
            <Typography variant="caption">Per Page:</Typography>
            <TextField
              select
              value={perPage}
              onChange={handlePerPageChange}
              sx={{ width: 80, ml: 1, mr: 4 }}
              size="small"
              variant="standard"
              // label="Per page"
            >
              {[5, 10, 25, 50].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

            <Typography variant="body2">
              {`${currentPage * perPage + 1}-${Math.min(
                (currentPage + 1) * perPage,
                totalRows
              )} of ${totalRows}`}
            </Typography>

            <Box>
              <IconButton onClick={handlePrevPage} disabled={currentPage === 0}>
                <ChevronLeft />
              </IconButton>
              <IconButton
                onClick={handleNextPage}
                disabled={currentPage >= totalPages - 1}
              >
                <ChevronRight />
              </IconButton>
            </Box>
          </Box>
        </Box>

        {loading && <LoadingSkeleton />}

        {paginatedTargets.map((target) => (
          <TargetCard
            searchData={searchData}
            key={target["domain"]}
            target={target}
            setTargets={setTargets}
            targets={targets}
            fetchTargetCount={fetchTargetCount}
          />
        ))}
      </Box>
    </Box>
  );
}

function SortContainer({ targets, setTargets, perPage, setPerPage }) {
  const SORT_OPTIONS = [
    "updated",
    "source",
    "domain",
    "name",
    "rating",
    "employees",
    "ownership",
  ];

  const [sortField, setSortField] = useState(
    localStorage.getItem("sortField") || "employees"
  );
  const [sortOrder, setSortOrder] = useState(
    localStorage.getItem("sortOrder") || "desc"
  );

  // Function to save sort preferences to localStorage
  const saveSearchSort = () => {
    localStorage.setItem("sortField", sortField);
    localStorage.setItem("sortOrder", sortOrder);
    localStorage.setItem("perPage", perPage);
  };

  // Function to sort the targets when sortField or sortOrder changes
  useEffect(() => {
    const sortedTargets = [...targets].sort((a, b) => {
      const fieldA = a[sortField];
      const fieldB = b[sortField];

      if (typeof fieldA === "string" && typeof fieldB === "string") {
        return sortOrder === "asc"
          ? fieldA.localeCompare(fieldB)
          : fieldB.localeCompare(fieldA);
      } else if (typeof fieldA === "number" && typeof fieldB === "number") {
        return sortOrder === "asc" ? fieldA - fieldB : fieldB - fieldA;
      } else {
        return 0;
      }
    });

    setTargets(sortedTargets);
    saveSearchSort();
  }, [sortField, sortOrder]); // Re-run whenever sortField or sortOrder changes

  const handleSortFieldChange = (e) => {
    setSortField(e.target.value);
  };

  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
  };

  return (
    <Box>
      <Box
        id="sort-header"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <TextField
            label="Sort Field"
            variant="standard"
            value={sortField}
            select
            sx={{ width: 110, mr: 2 }}
            onChange={handleSortFieldChange}
          >
            {SORT_OPTIONS.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Sort Order"
            variant="standard"
            value={sortOrder}
            select
            sx={{ width: 100 }}
            onChange={handleSortOrderChange}
          >
            {["asc", "desc"].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
    </Box>
  );
}

function LoadingSkeleton() {
  return (
    <Box sx={{ mt: 3 }}>
      {[...Array(5).keys()].map((i) => (
        <LoadingSkeletonCard key={i} />
      ))}
    </Box>
  );
}

function LoadingSkeletonCard() {
  return (
    <Card sx={{ mb: 4 }}>
      <CardContent>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Skeleton
              variant="rectangular"
              width={200}
              height={40}
              sx={{ my: 1 }}
              style={{ borderRadius: "5px" }}
            />
            <Skeleton
              variant="rectangular"
              width={320}
              height={60}
              sx={{ my: 1 }}
              style={{ borderRadius: "5px" }}
            />
          </Box>

          <Skeleton
            variant="rectangular"
            width={420}
            height={60}
            sx={{ my: 1 }}
            style={{ borderRadius: "5px" }}
          />
        </Box>
        <Skeleton
          variant="rectangular"
          width="80%"
          height={300}
          style={{ borderRadius: "5px" }}
        />
      </CardContent>
    </Card>
  );
}
