import GoogleSearchDrawer from "@/components/GoogleSearchDrawer";
import GrataDrawer from "@/components/GrataDrawer";
import ImportDrawer from "@/components/ImportDrawer";
import NotesDrawer from "@/components/NotesDrawer";
import { Box } from "@mui/material";

export default function LeftNav({
  searchData,
  currentView,
  fetchTargetCount,
  fetchTargets,
}) {
  return (
    <Box >
      <Box
        id="right-drawer-main"
        sx={{
          position: "fixed",
          backgroundColor: "rgba(19,19,19)",
          right: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 5,
          height: "100vh",
          width: 70,
          alignItems: "center",
          // borderLeft: "0.3px solid #e0e0e0",
          py: 3,
        }}
      >
        {searchData && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
            <GrataDrawer
              searchData={searchData}
              fetchTargets={fetchTargets}
              fetchTargetCount={fetchTargetCount}
              currentView={currentView}
            />
            <GoogleSearchDrawer
              searchData={searchData}
              fetchTargets={fetchTargets}
              fetchTargetCount={fetchTargetCount}
              currentView={currentView}
            />

            <ImportDrawer
              searchData={searchData}
              fetchTargets={fetchTargets}
              fetchTargetCount={fetchTargetCount}
              currentView={currentView}
            />

            
          </Box>
        )}
        <NotesDrawer searchData={searchData} />
      </Box>
    </Box>
  );
}
