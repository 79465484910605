import { Whatshot } from "@mui/icons-material";
import {
  Box,
  Link,
  Skeleton,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  DataGridPremium,
  GridToolbar,
  GRID_AGGREGATION_FUNCTIONS,
} from "@mui/x-data-grid-premium";
import axios from "axios";
import { useEffect, useState } from "react";

import { API_BASE } from "@/App";

export default function LeaderboardTable({}) {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 14)) // Subtract 14 days
      .toISOString()
      .split("T")[0] // Format to YYYY-MM-DD
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const getResults = async () => {
    try {
      setLoading(true);
      const endpoint = API_BASE + "/data/leaderboard";
      const response = await axios.get(endpoint, {
        params: {
          actor_key: localStorage.getItem("actorKey"),
          start_date: startDate,
          end_date: endDate,
        },
      });

      setResults(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getResults();
  }, [startDate, endDate]);

  const columns = [
    {
      field: "analyst",
      type: "string",
      width: 140,
    },
    {
      field: "search",
      width: 180,
      renderCell: (params) => (
        <Link
          href={`/search?label=${encodeURIComponent(
            params.value
          )}&currentView=land`} // Make params.value URI-safe
          style={{
            cursor: "pointer",
            textDecoration: "none",
            // color: "#04FFFF",
          }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "search_type",
      type: "string",
    },
    {
      field: "reject",
      headerName: "Reject",
      type: "number",
      width: 80,
      headerClassName: "weekly--header",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "advance",
      headerName: "Review",
      type: "number",
      width: 80,
      headerClassName: "weekly--header",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "rating",
      headerName: "Rated",
      type: "number",
      width: 80,
      headerClassName: "trailing--header",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "send",
      headerName: "Client Inbox",
      type: "number",
      width: 120,
      headerClassName: "auto--header",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "client_approve",
      // headerName: "Approved",
      type: "number",
      width: 120,
      headerClassName: "trailing--header",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "client_conflict",
      type: "number",
      width: 120,
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "client_reject",
      type: "number",
      width: 120,
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "approval_rate",
      headerName: "Approval %",
      type: "number",
      width: 120,
      valueFormatter: (value, row) => {
        return (value * 100).toFixed(0) + "%";
      },
    },
    {
      field: "hours",
      // headerName: "Hours",
      type: "number",
      width: 80,
      // headerClassName: "trailing--header",

      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
      valueGetter: (value) => {
        return parseFloat(value);
      },
      valueFormatter: (value) => {
        return value?.toFixed(1) || 0;
      },
    },
  ];

  const rowApprovalRate = {
    label: "row score",
    getCellValue: ({ row }) => ({
      client_approve: row.client_approve,
      client_conflict: row.client_conflict,
      client_reject: row.client_reject,
    }),
    apply: ({ values }) => {
      let client_approve = 0;
      let client_conflict = 0;
      let client_reject = 0;
      values.forEach((value) => {
        client_approve += value.client_approve;
        client_conflict += value.client_conflict;
        client_reject += value.client_reject;
      });
      const numerator = client_approve + client_conflict;
      const denominator = client_approve + client_conflict + client_reject;
      return denominator === 0 ? 0 : numerator / denominator;
    },
    type: "number",
  };

  return (
    <Box
      sx={{
        px: 4,
        "& .number-is-zero": {
          color: "#4E4B4B",
        },
        "& .trailing--header": {
          color: "#9A5AEF",
        },
        "& .weekly--header": {
          color: "#9A5AEF",
        },
        "& .auto--header": {
          color: "#04FFFF",
        },
        "& .daily--header": {
          color: "#bdbdbd",
        },
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 1,
          mt: 1,
          mb: 2,
        }}
      >
        <Box
          sx={{ width: "200px", display: "flex", alignItems: "center", gap: 1 }}
        >
          <Whatshot color="secondary" sx={{ fontSize: 24 }} />
          <Typography variant="h5" sx={{ my: 2 }}>
            Leaderboard
          </Typography>
        </Box>

        <Box sx={{ display: "flex", gap: 2, px: 4, alignItems: "center" }}>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {loading && <CircularProgress />}
        </Box>
      </Box>

      {loading && (
        <Skeleton
          variant="rectangular"
          height={540}
          style={{ borderRadius: "5px" }}
        />
      )}

      {!loading && (
        <DataGridPremium
          rows={results}
          columns={columns}
          getRowId={(row) => row.index}
          density="compact"
          slots={{
            toolbar: GridToolbar,
          }}
          pagination
          pageSizeOptions={[5, 10, 20, 50]}
          aggregationFunctions={{
            ...GRID_AGGREGATION_FUNCTIONS,
            rowApprovalRate,
          }}
          sx={{
            "& .MuiDataGrid-aggregationColumnHeaderLabel": {
              color: "white",
              opacity: 0.6,
              fontSize: "10px",
            },

            "& .MuiDataGrid-footerCell": {
              color: "#04FFFF",
            },
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
            sorting: { sortModel: [{ field: "send", sort: "desc" }] },
            rowGrouping: { model: ["analyst"] },
            filter: {
              filterModel: {
                items: [
                  {
                    field: "search_type",
                    operator: "doesNotEqual",
                    value: "Market Map",
                  },
                ],
              },
            },
            columns: {
              columnVisibilityModel: {
                analyst: false,
                search_type: false,
              },
            },
            aggregation: {
              model: {
                reject: "sum",
                advance: "sum",
                rating: "sum",
                send: "sum",
                comment: "sum",
                client_approve: "sum",
                hours: "sum",
                client_conflict: "sum",
                client_reject: "sum",
                search_uid: "max",
                approval_rate: "rowApprovalRate",
              },
            },
          }}
        />
      )}
    </Box>
  );
}
