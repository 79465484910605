import axios from "axios";
import React, { useState } from "react";

import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
  Box,
  TextField,
  IconButton,
} from "@mui/material";

import {ClearOutlined} from "@mui/icons-material";

import { fromNow } from "../../utils";

import { API_BASE } from "../../App";

export default function CommentCard({ comment, deleteComment }) {
  console.log(comment);
  

  
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: "10px",
      }}
    >
      <Box>
        <Typography
          variant="caption"
          color="lightgray"
          style={{ marginRight: "15px" }}
        >
          {comment["author"]}
        </Typography>
        <Typography variant="body">{comment.text}</Typography>
      </Box>

      <Box id="comment-actions" style={{ display: "flex" }}>
        <Typography id="time-ago" variant="caption" color="lightgray">
          {fromNow(comment["created"])}
        </Typography>

        <Box id="edit">
          <ClearOutlined
            style={{
              color: "darkgray",
              cursor: "pointer",
              marginLeft: "10px",
              marginTop: "-2px",
              fontSize: "1em",
            }}
            onClick={() => deleteComment(comment["id"])}
          />
        </Box>
      </Box>
    </Box>
  );
}
