import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";

import { Box } from "@mui/material";

import LeftNav from "./LeftNav";
import RightNav from "./RightNav";

import SearchAppBar from "./SearchAppBar";
import TargetCardContainer from "./TargetCardContainer";
import TargetTableContainer from "./TargetTableContainer";

import ErrorAlert from "@/fragments/ErrorAlert";

import { API_BASE } from "@/App";
import { getCompactState } from "@/utils";

export default function SearchResults() {
  const searchUid = useSearchParams()[0].get("searchKey"); // changing this requires updating/breaking prior sent Urls
  const searchLabel = useSearchParams()[0].get("label"); // changing this requires updating/breaking prior sent Urls
  const currentView = useSearchParams()[0].get("currentView", "land");

  const [loading, setLoading] = useState(true);

  const [error, setError] = useState(null);

  const [searchData, setSearchData] = useState(null);
  const [targets, setTargets] = useState([]);
  const [targetCount, setTargetCount] = useState({});

  // display stuff

  const compact = getCompactState();

  useEffect(() => {
    getSearchData();
  }, []);

  useEffect(() => {
    if (searchData) {
      fetchTargets();
      fetchTargetCount();
    }
  }, [searchData]);

  const getSearchData = async (e) => {
    try {
      const endpoint = API_BASE + "search";
      const response = await axios.get(endpoint, {
        params: {
          search_uid: searchUid,
          search_label: searchLabel,
          actor_key: localStorage.getItem("actorKey"),
        },
      });
      setSearchData(response.data);
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  };

  const fetchTargets = async () => {
    try {
      setError(null);
      setLoading(true);
      setTargets([]);

      const response = await axios.get(API_BASE + "targets", {
        params: {
          search_uid: searchData["uid"],
          stage: currentView,
        },
      });

      // Unpack all fields from target.meta onto the target object
      const unpackedTargets = response.data.map((target) => ({
        ...(target.meta || {}), // Unpack all properties from the meta field
        ...target,
      }));

      // set target.employees to Integer
      unpackedTargets.forEach((target) => {
        if (target.employees) {
          target.employees = parseInt(target.employees);
        } else {
          target.employees = 0;
        }
      });

      // Get sorting preferences from localStorage
      const sortField = localStorage.getItem("sortField") || "updated";
      const sortOrder = localStorage.getItem("sortOrder") || "desc";

      // Sort the unpacked targets based on the sortField and sortOrder
      const sortedTargets = unpackedTargets.sort((a, b) => {
        const fieldA = a[sortField];
        const fieldB = b[sortField];

        // Handle different types (e.g., numbers, strings)
        if (typeof fieldA === "string" && typeof fieldB === "string") {
          return sortOrder === "asc"
            ? fieldA.localeCompare(fieldB)
            : fieldB.localeCompare(fieldA);
        } else if (typeof fieldA === "number" && typeof fieldB === "number") {
          return sortOrder === "asc" ? fieldA - fieldB : fieldB - fieldA;
        } else {
          return 0; // Handle cases where the types don't match
        }
      });

      setTargets(sortedTargets);
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchTargetCount = async () => {
    try {
      const endpoint = API_BASE + "target/count";
      const response = await axios.get(endpoint, {
        params: {
          search_uid: searchData["uid"],
          actor_key: localStorage.getItem("actorKey"),
          // token: localStorage.getItem("accessToken"),
        },
      });
      setTargetCount(response.data);
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  };

  const helmetTitle = () => {
    let title = searchData?.label;
    if (window.location.origin.includes("localhost")) {
      title = "[dev] " + title;
    } else if (window.location.origin.includes("staging")) {
      title = "[staging] " + title;
    }
    return title;
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Helmet>
        <title>{helmetTitle()}</title>
      </Helmet>

      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <LeftNav
          searchData={searchData}
          currentView={currentView}
          targetCount={targetCount}
        />
        <Box
          id="targets-container"
          sx={{
            mt: 2,
            mb: 2,
            marginLeft: "150px",
            width: "calc(100vw - 150px)",
          }}
        >
          {/* {loading && <LoadingSkeleton />} */}
          <SearchAppBar
            searchData={searchData}
            currentView={currentView}
            fetchTargets={fetchTargets}
            fetchTargetCount={fetchTargetCount}
            targets={targets}
            setTargets={setTargets}
          />

          {!compact && (
            <TargetCardContainer
              searchData={searchData}
              targets={targets}
              targetCount={targetCount}
              setTargets={setTargets}
              fetchTargetCount={fetchTargetCount}
              fetchTargets={fetchTargets}
              currentView={currentView}
              loading={loading}
            />
          )}

          {compact && searchData && (
            <TargetTableContainer
              searchData={searchData}
              targets={targets}
              setTargets={setTargets}
              currentView={currentView}
              fetchTargetCount={fetchTargetCount}
            />
          )}

          {/* {currentView === "create" && (
            <PromptContainer
              searchData={searchData}
              currentView={currentView}
            />
          )} */}

          {/* <PromptContainer searchData={searchData} currentView={currentView} /> */}
        </Box>
        <RightNav
          searchData={searchData}
          currentView={currentView}
          fetchTargetCount={fetchTargetCount}
          fetchTargets={fetchTargets}
        />
      </Box>

      <ErrorAlert error={error} setError={setError} />
    </Box>
  );
}
