import axios from "axios";
import { useState } from "react";

import {
  Box,
  Button,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";

import {
  AccountBalance,
  AutoAwesome,
  CheckCircle,
  ThumbUp,
  Block,
  Send,
  Upload,
  Warning,
  Verified,
  ErrorOutline,
} from "@mui/icons-material";

import { API_BASE } from "@/App";
import { fromNow } from "@/utils";

export default function EventButtonsContainer({
  target,
  searchData,
  setTargets,
  targets,
  fetchTargetCount,
  enrichTarget,
}) {
  const [error, setError] = useState(null);
  const [conflictAnchorEl, setConflictAnchorEl] = useState(null);

  const targetStage = target["stage"];

  const postEvent = async (type) => {
    setTargets(targets.filter((t) => t.domain != target["domain"]));
    setConflictAnchorEl(null);

    try {
      const eventResponse = await axios.post(API_BASE + "transition_event", {
        search_uid: searchData["uid"],
        domain: target["domain"],
        type: type,
        data: {
          id: target["id"],
        },
        actor_key: localStorage.getItem("actorKey"),
      });

      fetchTargetCount();
    } catch (error) {
      console.log(error);
      setError(error.message);
      setTargets([...targets, target]);
      setError(error.message);
    }
  };

  // conflict menu stuff

  return (
    <Box id="button-group-container" style={{ marginTop: "10px" }}>
      {error && <Warning color="error" />}

      <Typography variant="caption" sx={{ opacity: 0.5, mr: 2 }}>
        {fromNow(target["updated"])}
      </Typography>
      <IconButton sx={{ mx: 2 }} color="auto" onClick={enrichTarget}>
        <AutoAwesome />
      </IconButton>

      <span>
        <Button
          startIcon={<ErrorOutline />}
          onClick={(event) => setConflictAnchorEl(event.currentTarget)}
          color="info"
        ></Button>
        <Menu
          id="conflict-menu"
          anchorEl={conflictAnchorEl}
          open={Boolean(conflictAnchorEl)}
          onClose={() => setConflictAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            color="info"
            onClick={(e) => postEvent("conflict")}
            style={{ textTransform: "uppercase" }}
          >
            Internal Conflict
          </MenuItem>
          <MenuItem
            color="info"
            onClick={(e) => postEvent("client_conflict")}
            style={{ textTransform: "uppercase" }}
          >
            Client Conflict
          </MenuItem>
        </Menu>
      </span>

      {targetStage != "buyer" && (
        <Tooltip title="Support the Fallout Strategy">
          <Button
            variant="text"
            startIcon={<AccountBalance />}
            onClick={(e) => postEvent("buyer")}
            color="info"
            style={{ margin: "0 10px" }}
          >
            Buyer
          </Button>
        </Tooltip>
      )}

      {targetStage != "reject" && (
        <Button
          variant="text"
          startIcon={<Block />}
          onClick={(e) => postEvent("reject")}
          color="error"
          style={{ margin: "0 10px" }}
        >
          Reject
        </Button>
      )}

      {targetStage == "send" && (
        <Button
          variant="contained"
          startIcon={<Block />}
          onClick={(e) => postEvent("client_reject")}
          color="warning"
          style={{ margin: "0 10px" }}
        >
          Client Reject
        </Button>
      )}

      {["create", "land", "similar"].includes(targetStage) && (
        <Button
          variant="contained"
          endIcon={<ThumbUp />}
          onClick={(e) => postEvent("advance")}
          color="primary"
          style={{ borderRadius: "20px" }}
        >
          Review
        </Button>
      )}

      {targetStage == "advance" && (
        <Tooltip
          title={
            target["rating"] == null
              ? "Rating required to validate"
              : "Research Validated"
          }
        >
          <span>
            <Button
              variant="contained"
              endIcon={<Verified />}
              onClick={(e) => postEvent("validate")}
              color="primary"
              style={{ borderRadius: "20px" }}
              // disabled={target["rating"] == null}
            >
              Validate
            </Button>
          </span>
        </Tooltip>
      )}

      {targetStage == "validate" && (
        <Button
          variant="contained"
          endIcon={<Send />}
          onClick={(e) => postEvent("send")}
          color="secondary"
          style={{ borderRadius: "20px" }}
        >
          Send
        </Button>
      )}

      {targetStage == "send" && (
        <Button
          variant="contained"
          endIcon={<CheckCircle />}
          onClick={(e) => postEvent("client_approve")}
          color="primary"
          style={{ margin: "0 10px" }}
        >
          Client Approved
        </Button>
      )}
      {targetStage == "client_approve" && (
        <Button
          variant="contained"
          endIcon={<Upload />}
          onClick={(e) => postEvent("sync")}
          color="success"
          style={{ borderRadius: "20px" }}
        >
          Sync
        </Button>
      )}
    </Box>
  );
}
