import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


export default function ErrorAlert({ error, setError }) {
    return (
        <>
            {
                error && (
                    <Snackbar
                        open={true}
                        onClose={() => setError(null)}
                    >
                        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
                            {error}
                        </Alert>
                    </Snackbar>
                )
            }
        </>

    )
}