import axios from "axios";

import { API_BASE } from "@/App";

export const fetchSearches = async (e) => {
  try {
    const endpoint = API_BASE + "searches";
    const response = await axios.get(endpoint, {
      params: {
        actor_key: localStorage.getItem("actorKey"),
      },
    });
    return response.data;
  } catch (error) {
    alert(error.message);
    console.error(error);
  }
};

export const importTargets = async (searchUid, domains, source, stage) => {
  try {
    // setLoading(true);
    const response = await axios.post(API_BASE + "import", {
      search_uid: searchUid,
      domains: domains,
      stage: stage,
      source: source.join(", "), // tbd
      actor_key: localStorage.getItem("actorKey"),
    });

    window.location.href = `/search?searchKey=${searchUid}&currentView=${targetStage}`;
  } catch (error) {
    alert(error);
    console.error(error);
  }
};

export const checkDomains = async (searchUid, domains) => {
  try {
    const response = await axios.post(
      `${API_BASE}check_domains`,
      {
        search_uid: searchUid,
        domains: domains,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = response.data;

    // Validate the response data
    if (!data || typeof data !== "object") {
      throw new Error("Invalid response data");
    }

    // Transform the data if necessary
    const transformedData = Object.entries(data).reduce(
      (acc, [domain, status]) => {
        acc[domain] = typeof status === "string" ? status : "unknown";
        return acc;
      },
      {}
    );

    return transformedData;
  } catch (error) {
    console.error("Error checking domains:", error);

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error("Error data:", error.response.data);
      console.error("Error status:", error.response.status);
      console.error("Error headers:", error.response.headers);
      throw new Error(
        `Server error: ${
          error.response.data.message || error.response.statusText
        }`
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("Error request:", error.request);
      throw new Error("No response received from server");
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error message:", error.message);
      throw new Error(`Request setup error: ${error.message}`);
    }
  }
};
