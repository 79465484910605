import { Info, ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Collapse,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";

import { API_BASE } from "@/App";
import { LABEL_MAP, ICON_MAP } from "@/fragments/Constants";
import { checkDomains } from "@/services/api";
import ResultCard from "./ResultCard";

export default function ResultsHandler({
  csv,
  targetStage,
  searchData,
  source,
  fetchTargets,
  fetchTargetCount,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [results, setResults] = useState({});
  const [showNewDomains, setShowNewDomains] = useState(false);
  const [expandedSections, setExpandedSections] = useState({});

  const handleSectionToggle = (stage) => {
    setExpandedSections((prev) => ({
      ...prev,
      [stage]: !prev[stage],
    }));
  };

  const moveTargets = async (fromStage) => {
    if (!searchData?.uid || !fromStage) {
      setError("Missing required data for move");
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const domains = conflictedResults.reduce((acc, [domain, stage]) => {
        if (stage === fromStage) {
          acc.push(domain);
        }
        return acc;
      }, []);

      await axios.post(`${API_BASE}move`, {
        search_uid: searchData.uid,
        domains: domains,
        stage: targetStage,
        source: Array.isArray(source) ? source.join(", ") : "",
        actor_key: localStorage.getItem("actorKey"),
      });

      fetchTargets(searchData.uid);
      fetchTargetCount(searchData.uid);

      const domainsArray = csv
        .split("\n")
        .map((domain) => domain.trim())
        .filter(Boolean);

      if (domainsArray.length) {
        debouncedCheckDomains(domainsArray);
      }
    } catch (error) {
      setError(error.message || "Failed to move targets");
      console.error("Move error:", error);
    } finally {
      setLoading(false);
    }
  };

  const importTargets = async () => {
    if (!searchData?.uid || !newDomains?.length) {
      setError("Missing required data for import");
      return;
    }

    try {
      setLoading(true);
      setError(null);
      await axios.post(`${API_BASE}import`, {
        search_uid: searchData.uid,
        domains: newDomains,
        stage: targetStage,
        source: Array.isArray(source) ? source.join(", ") : "",
        actor_key: localStorage.getItem("actorKey"),
      });

      window.location.href = `/search?searchKey=${searchData.uid}&currentView=${targetStage}`;
    } catch (error) {
      setError(error.message || "Failed to import targets");
      console.error("Import error:", error);
    } finally {
      setLoading(false);
    }
  };

  const checkDomainsHandler = useCallback(
    async (domainsArray) => {
      if (!searchData?.uid || !Array.isArray(domainsArray)) {
        return;
      }

      try {
        setLoading(true);
        setError(null);
        const response = await checkDomains(searchData.uid, domainsArray);
        setResults(response || {});
      } catch (error) {
        setError("Error checking domains");
        console.error("Domain check error:", error);
      } finally {
        setLoading(false);
      }
    },
    [searchData?.uid]
  );

  const debouncedCheckDomains = useCallback(
    debounce((domainsArray) => checkDomainsHandler(domainsArray), 500),
    [checkDomainsHandler]
  );

  useEffect(() => {
    if (!csv?.trim()) {
      setResults({});
      return;
    }

    const domainsArray = csv
      .split("\n")
      .map((domain) => domain.trim())
      .filter(Boolean);

    if (domainsArray.length) {
      debouncedCheckDomains(domainsArray);
    }
  }, [csv, debouncedCheckDomains]);

  const conflictedResults = Object.entries(results)
    .filter(([_, stage]) => stage !== "new")
    .sort(([_, stageA], [__, stageB]) => stageA.localeCompare(stageB));

  const newDomains = Object.entries(results)
    .filter(([_, stage]) => stage === "new")
    .map(([domain]) => domain);

  return (
    <Box sx={{ my: 2 }}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={importTargets}
          disabled={loading || newDomains.length === 0}
          sx={{ my: 2, borderRadius: "20px", flex: 1, mr: 1 }}
          size="large"
        >
          {loading ? (
            <CircularProgress size={24} sx={{ color: "inherit", mr: 1 }} />
          ) : newDomains.length === 0 ? (
            "No New Targets"
          ) : (
            `Import ${newDomains.length} New ${
              newDomains.length === 1 ? "Target" : "Targets"
            }`
          )}
        </Button>
        <IconButton
          onClick={() => setShowNewDomains((prev) => !prev)}
          color="info"
        >
          <Info />
        </IconButton>
      </Box>

      {showNewDomains && newDomains.length > 0 && (
        <Box sx={{ mt: 2, mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            New Domains:
          </Typography>
          <Box component="ul" sx={{ pl: 2 }}>
            {newDomains.map((domain) => (
              <li key={domain}>{domain}</li>
            ))}
          </Box>
        </Box>
      )}

      {conflictedResults.length > 0 && (
        <>
          <Typography variant="h6" gutterBottom>
            Targets in Search ({conflictedResults.length})
          </Typography>
          {Object.keys(LABEL_MAP).map((stage) => {
            const stageResults = conflictedResults.filter(
              ([_, currentStage]) => currentStage === stage
            );

            if (stageResults.length === 0) return null;

            return (
              <Box key={stage} sx={{ my: 2 }}>
                <Button
                  onClick={() => handleSectionToggle(stage)}
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",
                    px: 2,
                    py: 1,
                    mb: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.08)",
                    },
                  }}
                >
                  <Typography variant="h6">
                    {LABEL_MAP[stage]} ({stageResults.length})
                  </Typography>
                  {expandedSections[stage] ? <ExpandLess /> : <ExpandMore />}
                </Button>
                <Collapse in={expandedSections[stage]} timeout={0}>
                  <Box
                    sx={{
                      pl: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{ my: 1, borderRadius: "20px" }}
                      endIcon={ICON_MAP[targetStage]}
                      color="primary"
                      onClick={() => moveTargets(stage)}
                    >
                      Move All ({stageResults.length}) to{" "}
                      {LABEL_MAP[targetStage]}
                    </Button>

                    {stageResults.map(([domain]) => (
                      <ResultCard
                        key={domain}
                        domain={domain}
                        currentStage={stage}
                        targetStage={targetStage}
                        searchUid={searchData.uid}
                        source={source}
                        fetchTargetCount={fetchTargetCount}
                        fetchTargets={fetchTargets}
                      />
                    ))}
                  </Box>
                </Collapse>
              </Box>
            );
          })}
        </>
      )}
    </Box>
  );
}
