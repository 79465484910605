import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { GoogleOAuthProvider } from "@react-oauth/google";

import { Helmet } from "react-helmet";

import Home from "./pages/Home";
import SearchResults from "./pages/SearchResults";
import Reporting from "./pages/Reporting";
import Login from "./pages/Login";

import { getApiBase, getHelmetTitle } from "./utils";

const clientId =
  "471025930138-cgahb4fj4p5tignjog5k62kp96ivcbti.apps.googleusercontent.com";

export const API_BASE = getApiBase();

export default function App() {

  return (
    <GoogleOAuthProvider clientId={clientId}>
      
        <Helmet>
          <title>{getHelmetTitle()}</title>
        </Helmet>
        
        {
          !localStorage.getItem("accessToken") && <Login />
        }

        {localStorage.getItem("accessToken") && (
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/search" element={<SearchResults />} />
              <Route path="/reporting" element={<Reporting />} />
            </Routes>
          </Router>
        )}
      
    </GoogleOAuthProvider>
  );
}
