import { useEffect, useState } from "react";

import { AppBar, Box, Button, IconButton, Toolbar } from "@mui/material";

import AddCircleIcon from "@mui/icons-material/AddCircle";

import { DataUsage } from "@mui/icons-material";

import AnalyticsMenu from "./AnalyticsMenu";
import BuyersTable from "./BuyersTable";
import CreateSearchContainer from "./CreateSearch";
import SearchBox from "./SearchBox";
import SearchesTable from "./SearchesTable";

import ErrorAlert from "@/fragments/ErrorAlert";
import Footer from "@/fragments/Footer";

import logo from "@/images/logo.svg";

import { fetchSearches } from "@/services/api";

export default function Home() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searches, setSearches] = useState([]);

  const [open, setOpen] = useState(false);

  const loadSearches = async () => {
    try {
      setLoading(true);
      const data = await fetchSearches(); // Call the API function from services/api
      setSearches(data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadSearches(); // Call the loadSearches function when the component mounts
  }, []);

  return (
    <Box style={{ display: "flex", height: 800 }} sx={{ px: 4 }}>
      <ErrorAlert error={error} setError={setError} />
      <PageAppBar setOpen={setOpen} searches={searches} />

      <Box id="body" style={{ marginTop: 75, width: "100%", height: "500px" }}>
        {/* {loading && <CircularProgress />} */}
        <CreateSearchContainer open={open} setOpen={setOpen} />

        <SearchesTable searches={searches} />

        {/* <BuyersTable /> */}

        <Footer />
      </Box>
    </Box>
  );
}

function PageAppBar({ setOpen, searches }) {
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("actorKey");
    localStorage.removeItem("actorName");
    window.location.reload();
  };

  return (
    <AppBar position="fixed" variant="transparent" sx={{ px: 4 }}>
      <Toolbar sx={{ justifyContent: "space-between" }} disableGutters>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <img
            src={logo}
            style={{ width: "200px" }}
            onClick={() => (window.location.href = "/")}
          />
        </Box>
        {searches && <SearchBox searches={searches} />}
        <IconButton onClick={() => (window.location.href = "/reporting")}>
          <DataUsage color="secondary" />
        </IconButton>
        <AnalyticsMenu />
        <IconButton onClick={() => setOpen(true)}>
          <AddCircleIcon />
        </IconButton>
        <Button color="inherit" onClick={() => handleLogout()}>
          LOGOUT
        </Button>
      </Toolbar>
    </AppBar>
  );
}
