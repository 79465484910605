import React, { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";

import { CircularProgress } from "@mui/material";

export default function SearchBox({ searches }) {

  const [loading, setLoading] = useState(false);
  
  const [value, setValue] = useState(null);

  const gotoSearch = (e) => {
    window.location.href = `/search?searchKey=${value["uid"]}&currentView=land`;
  };

  const keyPress = (e) => {
    if (e.keyCode == 13) {
      gotoSearch();
    }
  };

  return (
    <Box id="search-box-container">
      {loading && (
        <Box
          id="search-box-loading"
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {!loading && searches && (
        <Box
          id="search-box-form"
          style={{ display: "flex", alignContent: "start" }}
        >
          <Autocomplete
            onKeyDown={keyPress}
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            options={searches}
            sx={{ width: 500, height: 50 }}
            renderInput={(params) => (
              <TextField {...params} label="Find Search" />
            )}
            renderOption={(props, option) => (
              <Box
                component="li"
                style={{ display: "flex", justifyContent: "space-between" }}
                {...props}
              >
                <Box style={{ display: "flex" }}>
                  <Typography variant="body2">{option["label"]}</Typography>
                </Box>
                <Box>
                  {option["analyst"] != "" && (
                    <Chip
                      size="small"
                      label={option["analyst"]}
                      style={{ marginRight: "5px" }}
                    />
                  )}
                </Box>
              </Box>
            )}
          />

          <Button
            onClick={gotoSearch}
            color="info"
            endIcon={<SearchIcon />}
            style={{ margin: "5px", width: "100px" }}
          >
            GO
          </Button>
        </Box>
      )}
    </Box>
  );
}
