import { API_BASE } from "@/App";
import { ICON_MAP, LABEL_MAP } from "@/fragments/Constants";
import { MoveDown } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useState, useCallback } from "react";

export default function MoveTargets({
  searchData,
  targets,
  setTargets,
  fetchTargetCount,
  apiRef,
  currentView,
}) {
  const [moveTo, setMoveTo] = useState(currentView);
  const [loading, setLoading] = useState(false);

  const handleMoveSelectedTargets = useCallback(async () => {
    const selected = apiRef.current.getSelectedRows();
    console.log(selected);
    const domains = [...selected.keys()];

    if (domains.length === 0) return;

    setLoading(true);

    try {
      // Clear selection state before updating targets
      apiRef.current.setRowSelectionModel([]);

      // Update local state
      setTargets(targets.filter((t) => !domains.includes(t.domain)));

      // Make API call
      await axios.post(API_BASE + "move", {
        search_uid: searchData.uid,
        actor_key: localStorage.getItem("actorKey"),
        domains: domains,
        stage: moveTo,
      });

      // Refresh counts
      await fetchTargetCount();
    } catch (error) {
      console.error("Error moving targets:", error);
      // Optionally revert the local state change on error
      // setTargets(previousTargets);
    } finally {
      setLoading(false);
    }
  }, [apiRef, targets, setTargets, searchData.uid, moveTo, fetchTargetCount]);

  const handleStageChange = useCallback((event) => {
    setMoveTo(event.target.value);
  }, []);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1, my: 4 }}>
      <TextField
        select
        label="Move to"
        value={moveTo}
        onChange={handleStageChange}
        style={{ minWidth: "120px" }}
        size="small"
      >
        {Object.keys(LABEL_MAP).map((key) => (
          <MenuItem key={key} value={key}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
              {ICON_MAP[key]}
              {LABEL_MAP[key]}
            </Box>
          </MenuItem>
        ))}
      </TextField>

      <Button
        variant="outlined"
        onClick={handleMoveSelectedTargets}
        startIcon={loading ? <CircularProgress size={20} /> : <MoveDown />}
        disabled={loading}
      >
        Move Selected
      </Button>
    </Box>
  );
}
