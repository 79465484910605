import React from "react";
import { useNavigate } from "react-router-dom";

import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import SearchCommentsContainer from "../../components/SearchCommentsContainer";

export default function SearchCommentsDrawer({ searchData, open, setOpen }) {
  const navigate = useNavigate();
  return (
    <Drawer
      anchor="right"
      variant="persistent"
      open={open}
      style={{ zIndex: 64 }}
      onClose={() => setOpen(false)}
    >
      <AppBar position="static" sx={{ mb: 4 }} variant="transparent">
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
          <Box>
            <Typography variant="h6">{searchData?.label}</Typography>
          </Box>
          
          <IconButton
            onClick={() =>
              navigate(`/search?searchKey=${searchData?.uid}&currentView=land`)
            }
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2, width: "800px" }}>
        

        <SearchCommentsContainer searchData={searchData} />
      </Box>
    </Drawer>
  );
}
