import { ArrowBackIos, DataUsage } from "@mui/icons-material";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import Consistency from "./Consistency";
import Leaderboard from "./Leaderboard";
import Searches from "./Searches";
import Time from "./Time";

export default function Reporting({}) {
  return (
    <Box sx={{ pb: 10 }}>
      <AppBar position="sticky" variant="transparent">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <IconButton onClick={() => window.history.back()}>
              <ArrowBackIos />
            </IconButton>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <DataUsage color="secondary" />
            <Typography variant="h5">TargetSelect Reporting</Typography>
          </Box>
          <Box />
        </Toolbar>
      </AppBar>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Leaderboard />
        <Searches />
        <Consistency />
        <Time />
      </Box>
    </Box>
  );
}
