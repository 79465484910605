import axios from "axios";
import React, { useEffect, useState } from "react";

import { Box, CardMedia, Skeleton, Typography } from "@mui/material";

import { Timer } from "@mui/icons-material";
import { API_BASE } from "../../App.js";

export default function Time({}) {
  // const [html, setHtml] = useState();

  const [imageSrc, setImageSrc] = useState("");

  const fetchImage = async () => {
    try {
      const endpoint = API_BASE + "/visualize/hours";
      const response = await axios.get(endpoint, {
        responseType: "arraybuffer",
      });
      const base64 = btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      setImageSrc(`data:image/png;base64,${base64}`);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  useEffect(() => {
    fetchImage();
  }, []);

  return (
    <Box
      sx={{
        my: 4,
        px: 4,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Timer color="secondary" sx={{ fontSize: 24 }} />
        <Typography variant="h5" sx={{ my: 2 }}>
          Time
        </Typography>
      </Box>
      <Box style={{ marginTop: "-20px" }} sx={{ mb: 2 }}>
        <Typography variant="caption" sx={{ ml: 4, color: "info.main" }}>
          Total hours spent in platform per day.
        </Typography>
      </Box>
      {imageSrc && (
        <CardMedia
          component="img"
          src={imageSrc}
          sx={{
            width: "100%",
            height: "auto",
            borderRadius: "5px",
            border: "1px solid #4E4B4B",
          }}
        />
      )}

      {!imageSrc && (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="600px"
          sx={{ borderRadius: "5px" }}
        />
      )}
    </Box>
  );
}
