import { AddCircle, KeyboardDoubleArrowRight } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Container,
  Drawer,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import { ICON_MAP, LABEL_MAP, SOURCE_OPTIONS } from "@/fragments/Constants";

import ResultsHandler from "./ResultsHandler";

export default function ImportDrawer({
  searchData,
  fetchTargets,
  fetchTargetCount,
  currentView,
}) {
  const [open, setOpen] = useState(false);
  const [targetStage, setTargetStage] = useState("land");
  const [source, setSource] = useState([]);
  const [csv, setCsv] = useState("");

  useEffect(() => {
    setTargetStage(currentView);
  }, [currentView]);

  const handleClose = () => setOpen(false);

  return (
    <Box>
      <IconButton color="secondary" onClick={() => setOpen(true)}>
        <AddCircle />
      </IconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { width: "700px" } }}
      >
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", left: 10, top: 10 }}
          color="info"
        >
          <KeyboardDoubleArrowRight />
        </IconButton>

        <Container sx={{ my: 8 }}>
          <Typography variant="h5" gutterBottom>
            Import Targets
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
            <Box sx={{ width: "100%" }}>
              <TextField
                select
                value={targetStage}
                onChange={(e) => setTargetStage(e.target.value)}
                required
                label="Stage"
                variant="outlined"
                fullWidth
                sx={{ my: 2 }}
              >
                {Object.keys(LABEL_MAP).map((key) => (
                  <MenuItem key={key} value={key}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                      {ICON_MAP[key]}
                      {LABEL_MAP[key]}
                    </Box>
                  </MenuItem>
                ))}
              </TextField>

              <Autocomplete
                multiple
                freeSolo
                value={source}
                onChange={(e, newValue) => setSource(newValue)}
                options={SOURCE_OPTIONS}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Data Source"
                    variant="outlined"
                    helperText="Where did this come from? Select or type + enter."
                    sx={{ my: 2 }}
                  />
                )}
              />

              <TextField
                value={csv}
                onChange={(e) => setCsv(e.target.value)}
                required
                helperText="Add one domain per line / paste from spreadsheet"
                label="Copy/paste domain(s) here"
                autoComplete="off"
                variant="outlined"
                fullWidth
                multiline
                minRows={2}
                maxRows={8}
              />
            </Box>
          </Box>
          <ResultsHandler
            csv={csv}
            source={source}
            searchData={searchData}
            targetStage={targetStage}
            fetchTargets={fetchTargets}
            fetchTargetCount={fetchTargetCount}
          />
        </Container>
      </Drawer>
    </Box>
  );
}
