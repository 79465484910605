import React, { useState, useEffect } from 'react';
import axios from 'axios'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import GoogleIcon from '@mui/icons-material/Google';

import { useGoogleLogin } from '@react-oauth/google';

import { API_BASE } from "../App.js"

import logo from "../images/logo.svg"


// https://blog.logrocket.com/guide-adding-google-login-react-app/

export default function LoginContainer() {

  const [loading, setLoading] = useState(false)

  const handleLogin = async (response) => {
    setLoading(true)
    const access_token = response['access_token']
    const profileEndpoint = `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`
    const profile = await axios.get(profileEndpoint, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        Accept: 'application/json'
      }
    })

    console.log(profile.data)

    const actorEndpoint = API_BASE + "actor"
    const actor = await axios.post(actorEndpoint,
      {
        "email": profile.data.email,
        "name": profile.data.name,
      }
    );
    console.log(actor.data)
    localStorage.setItem("accessToken", access_token)
    localStorage.setItem("actorKey", actor.data['key'])
    localStorage.setItem("actorName", actor.data['name'])
    window.location.reload()
  }

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => handleLogin(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });

  return (
    <Container maxWidth="sm" style={{ marginTop: 200, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

      <img src={logo} style={{ width: 500 }} />

      <Box style={{ marginTop: 50 }}>
        {
          loading ? (
            <CircularProgress />
          ) : (
            <Button
              variant='outlined'
              size='large'
              color='inherit'
              startIcon={<GoogleIcon />}
              onClick={() => login()}
            >
              Login with Google
            </Button>
          )
        }


      </Box>


    </Container>
  );
};






