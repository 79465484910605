import { LABEL_MAP } from "@/fragments/Constants";
import { getIcon } from "@/utils";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip
} from "@mui/material";

export default function LeftNav({ searchData, currentView, targetCount }) {
  const setView = (name) => {
    window.location.href = `/search?searchKey=${searchData["uid"]}&currentView=${name}`;
  };

  const getColor = (key) => (currentView === key ? "primary" : "inherit");

  

  return (
    <Box
      id="left-drawer-main"
      sx={{
        position: "fixed",
        backgroundColor: "rgba(19,19,19)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        height: "100vh",
        width: 150,
        py: 6,
        zIndex: 1000,
      }}
    >
      

      <List>
        {Object.keys(LABEL_MAP).map((key) => (
          <ListItem key={key}>
            <Tooltip title={LABEL_MAP[key]} placement="right-start">
              <ListItemButton
                onClick={() => setView(key)}
                selected={currentView === key}
              >
                <ListItemIcon>{getIcon(key, getColor(key))}</ListItemIcon>
                <ListItemText
                  primary={targetCount[key]}
                  
                />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        ))}
      </List>

      {/* <Switch
        checked={JSON.parse(localStorage.getItem("compact")) || false}
        onChange={toggleCompact}
        inputProps={{ "aria-label": "controlled" }}
        sx={{ ml: 3 }}
      /> */}
    </Box>
  );
}
