import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";

import { API_BASE } from "../../App";

export default function CreateSearchContainer({ open, setOpen }) {
  const [loading, setLoading] = useState(false);
  const [uid, setUid] = useState("");
  const [label, setLabel] = useState("");

  const navigate = useNavigate();

  const insertSearch = async () => {
    try {
      setLoading(true);
      const endpoint = API_BASE + "search";
      const repsonse = await axios.post(endpoint, {
        uid: uid,
        label: label,
        actor_key: localStorage.getItem("actorKey"),
      });
      // this will get thrown off
      setLoading(false);
      // window.location.reload()
      navigate(`/search?searchKey=${uid}&currentView=land`);
    } catch (error) {
      console.error(error);
      alert(error.message);
      window.location.reload();
    }
  };

  const handleUidChange = (e) => {
    const input = e.target.value;

    // Check if the input is a URL or just a number
    if (input.includes("/")) {
      // Extract the last part after the last "/"
      const uidFromUrl = input.split("/").pop();
      setUid(uidFromUrl);
    } else {
      // If it's not a URL, just set it directly
      setUid(input);
    }
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
      }}
    >
      {open && (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "25px",
            maxWidth: "700px",
          }}
        >
          <Button
            startIcon={<ClearIcon />}
            onClick={() => setOpen(false)}
            variant="outlined"
            color="info"
            style={{ marginBottom: "15px" }}
          >
            Cancel
          </Button>

          <TextField
            value={uid}
            // onChange={(e) => setUid(e.target.value)} // or, url
            onChange={handleUidChange}
            disabled={loading}
            helperText="or, dealcloud_id directly"
            label="dealcloud engagement url"
            autoComplete="off"
            variant="outlined"
            style={{ marginBottom: "10px", width: "400px" }}
          />
          <TextField
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            disabled={loading}
            helperText="label for this search"
            label="label"
            autoComplete="off"
            variant="outlined"
            style={{ marginBottom: "10px" }}
          />

          <Button
            variant="contained"
            fullWidth
            onClick={insertSearch}
            style={{ marginTop: "15px" }}
          >
            Create Search
          </Button>
        </Box>
      )}
    </Box>
  );
}
