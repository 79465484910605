import React, { useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";

import { API_BASE } from "../App";

export default function RatingContainer({
  target,
  targets,
  setTargets,
  fetchTargetCount,
}) {
  // NB: pass the rating, setRating so that the validate button can be disabled if no rating is set
  const currentView = useSearchParams()[0].get("currentView");

  const [rating, setRating] = useState(target.rating);

  const handleRating = async (e, newValue) => {
    // Do nothing if the newValue is null
    // e.stopPropagation();
    if (newValue === null) {
      return;
    }

    // Send rating update to the server
    axios.post(API_BASE + "rate_target", {
      search_uid: target.search_uid,
      domain: target.domain,
      type: "rating",
      actor_key: localStorage.getItem("actorKey"),
      data: {
        rating: newValue,
        currentView: currentView,
      },
    });

    // Update the local rating state
    setRating(newValue);

    // Conditional filtering based on specific views
    const filterStages = [
      "land",
      "create",
      "advance",
      "hold",
      "similar",
      "reject",
    ];
    if (filterStages.includes(currentView)) {
      setTargets(targets.filter((t) => t.domain !== target.domain));
    }

    // Delay and fetch target count after filtering
    await new Promise((r) => setTimeout(r, 4000));
    fetchTargetCount();
  };

  return <Rating value={rating} onChange={handleRating} />;
}
